import './ChatList.css'

import { useCallback, useEffect, useRef, useState } from "react";
import ChatRow from './ChatRow'
import LoadingChatRow from "./LoadingChatRow";
import { CHAT_MESSAGE_UPDATED_EVENT, CreateEvent } from "../util/eventListener";

interface ChatListProps {
  items: ChatMessage[];
  userId: number | string;
  roomOwnerId: string;
  meetingId: string;
  showLoadingChat: boolean;
}

const SCROLL_THRESHOLD = 100; // スクロール判定の閾値

const ChatList = ({ items, userId, roomOwnerId, meetingId, showLoadingChat }: ChatListProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  // スクロール位置を監視し、下部付近にいるかどうかを判定
  const handleScroll = () => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

    // 下部からSCROLL_THRESHOLD以内にいる場合、自動スクロールを有効に
    setShouldAutoScroll(distanceFromBottom <= SCROLL_THRESHOLD);
  };

  // スクロール処理を行う関数
  const scrollToBottom = useCallback(() => {
    if (!shouldAutoScroll || !containerRef.current) return;

    containerRef.current.scrollTo({
      top: containerRef.current.scrollHeight,
      behavior: 'smooth'
    });
  }, [shouldAutoScroll]);

  // メッセージリストの更新を監視
  useEffect(() => {
    scrollToBottom();
  }, [items, scrollToBottom]);

  // メッセージ内容の更新イベントを監視
  useEffect(() => {
    const handleMessageUpdate = () => {
      scrollToBottom();
    };
    return CreateEvent(CHAT_MESSAGE_UPDATED_EVENT, handleMessageUpdate);
  }, [scrollToBottom]);

  return (
      <div
          ref={containerRef}
          className='chat-container container py-3'
          onScroll={handleScroll}
      >
        {items.map((message, i) => (
            <ChatRow key={message.id} user={userId} item={message} roomOwnerId={roomOwnerId} meetingId={meetingId}/>
        ))}
        {showLoadingChat && (
            <LoadingChatRow mine={true}/>
        )}
      </div>
  )
}

export default ChatList
