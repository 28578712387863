import './ChatList.css'

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Tooltip } from 'react-tooltip';
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LoadingChatRow from "./LoadingChatRow";
import {observer} from "mobx-react-lite";
import 'github-markdown-css/github-markdown.css';
import { useTranslation } from 'react-i18next';
import { ADVISOR_CHAT_MESSAGE_UPDATED_EVENT, CreateEvent, DispatchEvent } from "../util/eventListener";

const SCROLL_THRESHOLD = 100;

const AdvisorChatRow = observer(({item, retryMessage}: { item: AdvisorChatMessage, retryMessage: (content: string) => void }) => {
    const { t } = useTranslation();

    // メッセージ内容の更新を監視し、イベントを発火
    useEffect(() => {
        DispatchEvent(ADVISOR_CHAT_MESSAGE_UPDATED_EVENT);
    }, [item.content]);

    return (
        <div className={`chat-row ${item.role === 'user' ? 'mine' : 'other'} row justify-content-${item.role === 'user' ? 'end' : 'start'}${item.role === 'assistant' && item.content === '' ? ' d-none' : ''}`}>
            <div className='col-auto flex-shrink-1'>
                {item.role === 'assistant' && (
                    <img className='user-avatar'
                         data-bs-toggle='tooltip'
                         data-bs-placement='top'
                         title={`donutAI`}
                         src='/icon-donutAI.png' alt={`donutAI`}/>
                )}
                <div className='chat-text-container'>
                    {item.role === 'assistant' && (
                        <div className='ms-1'>
                            <small>donutAI</small>
                        </div>
                    )}
                    {item.role === 'assistant' ? (
                      <div className="chat-text text-black">
                          <ReactMarkdown
                            className="markdown-body"
                            rehypePlugins={[rehypeRaw]}>{item.content}</ReactMarkdown>
                      </div>
                      ) : (
                      <>
                          {item.error && (
                            <span
                              className="chat-alert-mark text-danger">
                                <i className="bi bi-exclamation-circle-fill"></i>
                            </span>
                          )}
                          <div className={`chat-text text-black d-inline-block ${item.error && 'chat-text-error'}`}>
                              <span>{item.content}</span>
                          </div>
                          {item.error && (
                            <span
                              data-tooltip-id={`chat-message-${item.id}-repeat`}
                              data-tooltip-class-name="chat-message-tooltip"
                              className="chat-alert-mark chat-retry text-danger"
                              onClick={() => retryMessage(item.content)}>
                                <i className="bi bi-arrow-repeat"></i>
                                <Tooltip
                                  id={`chat-message-${item.id}-repeat`}
                                  place="bottom"
                                  variant="error"
                                  content={t('メッセージを再送信')}
                                />
                            </span>
                          )}
                      </>
                      )}
                </div>
            </div>
        </div>
    )
});

interface AdvisorContentProps {
    items: AdvisorChatMessage[];
    showLoadingChat: boolean;
    retryMessage: (content: string) => void;
}

const AdvisorContent = ({items, showLoadingChat, retryMessage}: AdvisorContentProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

    // スクロール位置を監視し、下部付近にいるかどうかを判定
    const handleScroll = () => {
        if (!containerRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

        // 下部からSCROLL_THRESHOLD以内にいる場合、自動スクロールを有効に
        setShouldAutoScroll(distanceFromBottom <= SCROLL_THRESHOLD);
    };

    // スクロール処理を行う関数
    const scrollToBottom = useCallback(() => {
        if (!shouldAutoScroll || !containerRef.current) return;

        containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: 'smooth'
        });
    }, [shouldAutoScroll]);

    // メッセージリストの更新を監視
    useEffect(() => {
        scrollToBottom();
    }, [items, scrollToBottom]);

    // メッセージ内容の更新イベントを監視
    useEffect(() => {
        const handleMessageUpdate = () => {
            scrollToBottom();
        };
        return CreateEvent(ADVISOR_CHAT_MESSAGE_UPDATED_EVENT, handleMessageUpdate);
    }, [scrollToBottom]);

    return (
        <div ref={containerRef} onScroll={handleScroll} className='advisor-container container py-3'>
            {items.map((message, i) => (
                <AdvisorChatRow key={message.id} item={message} retryMessage={retryMessage} />
            ))}
            {showLoadingChat && (
                <LoadingChatRow mine={false} autoHidden={false}/>
            )}
        </div>
    )
}

export default AdvisorContent
