import './MainPage.css'
import './SummaryOnly.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import useQuery from '../hook/useQuery';

import { LanguageFlag } from '../component/LanguageFlag'
import ChatList from '../component/ChatList'
import { LocalStorage } from '../store/LocalStorage'
import { guestLanguage, ILanguageObject, ILanguages, LanguageList } from '../util/language';
import Messages from '../store/Messages'
import { UserInfoModal } from '../component/UserInfoModal'
import { TroubleshootingModal } from '../component/TroubleshootingModal'
import { ConfirmAIAdvisorModal } from "../component/ConfirmAIAdvisorModal";
import { ConfirmMeetingActiveModal } from '../component/ConfirmMeetingActiveModal'
import { MEETING_FINISH_MODAL_ACTION, MeetingFinishModal } from "../component/MeetingFinishModal";
import { SttChangeModal } from "../component/SttChangeModal";
import { INITIAL_ID, parseQueryParam, randomId, isNotProduction } from '../util/util'
import { useChat } from '../hook/useChat'
import { textToSpeech } from '../util/TTS'
import RecordButton from '../component/RecordButton'
import { RecordButtonHandles } from '../types/speech';
import { addTranslation } from '../api/GoogleApi';
import { Modal } from '../component/Modal'
import { useParticipants } from '../hook/useParticipants'
import { addOrUpdateSpeaker } from '../hook/useSpeakers'
import { Speaker } from '../types/speaker'
import  PerticipantsWidget from '../component/participantsWidget'

/* === Svg's === */
import { ReactComponent as SpeakerOn } from '../assets/images/speaker.svg'
import { ReactComponent as SpeakerOff } from '../assets/images/speaker_off.svg'
import { ReactComponent as WindowStack } from '../assets/images/window-stack.svg'
import { ReactComponent as CaretUpFill } from '../assets/images/caret-up-fill.svg'
import { ReactComponent as CaretDownFill } from '../assets/images/caret-down-fill.svg'

import { Crystal } from '../component/Crystal'
import { bootstrapQuery, useMediaQuery } from '../hook/useMediaQuery'
import AudioManager from '../store/AudioManager'
import { audioMasterAnalyserBus } from '../util/audioContext'
import AdvisorStateInst from '../store/AdvisorState'
import ConfirmMeetingActiveModalStateInst from '../store/ConfirmMeetingActiveModalState'
import { sentryLog, sentrySetUser } from '../util/sentry';
import { updateUserLanguage } from '../hook/useUserLanguage';
import AdvisorContent from "../component/AdvisorContent";
import advisorMessages from "../store/AdvisorMessages";
import AdvisorForm from "../component/AdvisorForm";

import { useToast } from '../context/ToastContext';
import { Toaster as ReactHotToast } from 'react-hot-toast';
import { CurrentProgress } from '../component/CurrentProgress';

const USER_ID = 'local_user_uniq'

const SelfTranslateModal = ({ open, lang, onSelect }: { open: boolean, lang: ILanguages | null, onSelect: (lang: ILanguages | null) => void }) => {
  const { t } = useTranslation()

  return (
    <Modal.Root open={open}>
      <Modal.Header>{t("発言翻訳先の言語指定")}</Modal.Header>
      <Modal.Body>
        <div className='row border rounded pt-3' style={{ flex: 1, overflowY: 'auto' }}>
          <div className='row language-select mx-0'>
            <div className='col-12 text-center text-secondary h5'>
              {t("発言の自動翻訳先を指定してください。")}
            </div>
            {/*<div className='col-6 col-sm-4 col-md-3 col-xl-2 mb-2'>
              <LanguageFlag active={lang == null} showName
                language={null} onItemClick={onSelect} />
            </div>*/}
            {Object.values(LanguageList).map((language) => (
              <div key={language.id} className='col-6 col-sm-4 col-md-3 col-xl-2 mb-2'>
                <LanguageFlag active={language.id === lang} showName
                              language={language} onItemClick={onSelect} />
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal.Root>
  )
}

type ToggleSpeakerSpeechProps = JSX.IntrinsicElements['div'] & {
  stopAdvisory: () => void
}

const ToggleSpeakerSpeech = observer(({ stopAdvisory, ...divProps }: ToggleSpeakerSpeechProps) => {
  const { t } = useTranslation()
  const SelfSpeakComponent = (
    LocalStorage.speakTranslateLocal
    ? SpeakerOn
    : SpeakerOff
  )
  const SpeakComponentStyle = { width: 35, height: 35 }

  if (LocalStorage.language.length === 0 && LocalStorage.translationLanguage == null) {
    return null
  }

  const handleSpeakTranslateLocalClick = useCallback(() => {
    LocalStorage.setValue('speakTranslateLocal', !LocalStorage.speakTranslateLocal);
    stopAdvisory();
  }, [stopAdvisory]);

  return(
    <div {...divProps}>
      <button
        className="flex-shrink-1 btn badge rounded-circle shadow-none"
        title={t("翻訳されたメッセージを発話する")}
        onClick={handleSpeakTranslateLocalClick}
      >
        <SelfSpeakComponent style={SpeakComponentStyle} />
      </button>
    </div>
  )
})

type HeaderProps = {
  onLeftFlagClick:  () => void,
  onRightFlagClick: () => void,
  onClickCrystal: () => void,
  stopAdvisory: () => void,
  // AIアドバイザー機能を使用するかどうか（「お話してもよろしいですか？」を表示するかどうか）
  // デフォルト値は false ミーティング参加時のソケット通信で設定値を受け取る（ useChat.ts にて定義・更新される）
  enableAIAdvisor: boolean,
  doUserOperation: boolean,
}

const Header = observer((props: HeaderProps) => {
  const {t} = useTranslation()
  const isOnPC = useMediaQuery(bootstrapQuery.md)

  const leftFlagLanguage: ILanguageObject[] = LocalStorage.language ? LocalStorage.language.map(l => LanguageList[l]) : [LanguageList[ILanguages["ja-JP"]]];

  // 翻訳言語
  const rightFlagLanguage: ILanguageObject | null = (
    LocalStorage.translationLanguage
      ? LanguageList[LocalStorage.translationLanguage]
      : null
  )

  return (
    <section className='chat-header flex-fill'>
      <div className='row justify-content-stretch align-items-end'>
        <div className="col-4 logo-wrap text-center">
          {!isOnPC && <ToggleSpeakerSpeech stopAdvisory={props.stopAdvisory} />}
          <img
            className={isOnPC ? 'gptech-logo' : 'd-none'}
            src="/donutai_agent_1_0_logo.png"
            alt="gptech-logo"
          />
          <img
            className={isOnPC ? 'd-none' : 'gptech-logo-phone'}
            src="/donutai_agent_1_0_logo_phone.png"
            alt="gptech-logo"
          />
        </div>

        {!LocalStorage.roomClosed && (
          <Crystal onClickCrystal={props.onClickCrystal} enableAIAdvisor={props.enableAIAdvisor}
                   doUserOperation={props.doUserOperation} />)}

        {(!!LocalStorage.name) && (
          <div className='col-4 lang-wrap ms-auto position-relative'>
            <div className='row gx-0 align-items-center'>
              {isOnPC ? (
                <>
                  {/*
                    for a PC!!
                  */}
                  <div className='text-truncate text-end col-6'>
                    <b>{LocalStorage.name}</b>
                  </div>
                  <div className='col-2'>
                    {t('さん')}
                  </div>

                  <span className='w-100'></span>
                  <div className='flag-button justify-content-center col-8' style={{width: "auto"}}>
                    {leftFlagLanguage.map((language, index) => (
                      <LanguageFlag key={index} language={language} onClick={props.onLeftFlagClick} />
                    ))}
                    <span>⇔</span>
                    <LanguageFlag language={rightFlagLanguage}  onClick={props.onRightFlagClick} />
                  </div>

                  <ToggleSpeakerSpeech
                    stopAdvisory={props.stopAdvisory}
                    className='col-4 d-flex justify-content-end align-items-center'
                  />
                </>
              ) : (
                <>
                  {/*
                    for a phone!!!
                  */}
                  <div className='text-center text-center col-12'>
                    <b>{LocalStorage.name}</b>
                  </div>

                  <div className='flag-button justify-content-center col-12' style={{width: "auto"}}>
                    <LanguageFlag language={leftFlagLanguage[0]} onClick={props.onLeftFlagClick} />
                    <span>⇔</span>
                    <LanguageFlag language={rightFlagLanguage}  onClick={props.onRightFlagClick} />
                  </div>
                </>
              )}
            </div>
          </div>
        )}

      </div>
    </section>
  )
})


export const MainPage = observer(() => {
  const { t } = useTranslation()
  const { setIsEnabled } = useToast();

  const [showSttChangeModal, setShowSttChangeModal] = useState(false)
  const [showConfirmAIAdvisorModal, setShowConfirmAIAdvisorModal] = useState(false)
  const [showConfirmMeetingActiveModal, setShowConfirmMeetingActiveModal] = useState(false)
  const [showTroubleshootingModal, setShowTroubleshootingModal] = useState(false)
  const [showMeetingFinishModal, setShowMeetingFinishModal] = useState(false)
  const [showInputModal, setShowInputModal] = useState(true)
  const [expandLanguageList, setExpandLanguageList] = useState(false)
  const [openTranslatingModal, setOpenTranslatingModal] = useState(false)
  const [recordValues, setRecordValues] = useState({isRecording:false, isDisabled:false});
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [showLoadingChat, setShowLoadingChat] = useState(false)
  const [showLoadingAdvisorChat, setShowLoadingAdvisorChat] = useState(false)
  const [isShowChatContent, setIsShowChatContent] = useState(true)

  const [doUserOperation, setDoUserOperation] = useState(false);

  const { getSummaryFlag, paramSummaryWindow, paramShareWindow, receiveMessage } = useQuery();
  const summaryFlag = getSummaryFlag();

  if(summaryFlag) {
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop?.parentNode?.removeChild(backdrop);
    }
  }

  receiveMessage();

  const handleSelfTranslatingSelection = (lang: ILanguages | null) => {
    LocalStorage.setValue('translationLanguage', lang)
    setOpenTranslatingModal(false)
  }

  const recordRef = useRef<RecordButtonHandles>(null);
  const timerRef = useRef(null);
  const chatContentRef = useRef<HTMLDivElement>(null);
  const advisorContentRef = useRef<HTMLDivElement>(null);
  const bsCollapseChatContentRef = useRef<any>(null);
  const bsCollapseAdvisorContentRef = useRef<any>(null);

  const token = useMemo(() => {
    let token = parseQueryParam('t')
    if (!token) {
      LocalStorage.setValue('roomState', 'notexist');
    }
    return token
  }, [])
  const { sendMessage, createTrans, changeName, changeLabels, agendaAdd, agendaEdit, agendaDelete, agendaChange, initiateAdvisory: initAdvisor, stopAdvisory, roomClose, advisorChatRequest, meetingIdRef, enableAIAdvisorRef, roomOwnerIdRef } = useChat(AdvisorStateInst, token)
  const roomOwnerId = roomOwnerIdRef.current as string;
  const meetingId = meetingIdRef.current as string;
  const enableAIAdvisor = enableAIAdvisorRef.current as boolean;
  const participants = useParticipants(roomOwnerId, meetingId);

  useEffect(() => {
    // mediaDevices support
    if (!navigator.mediaDevices) {
      console.warn('このブラウザを対応しません。')
    }

    // ask user to input name and language
    if (token) {
      if (!LocalStorage.name || !LocalStorage.language) {
        setShowInputModal(true)
      }
    }

    return () => {
      setShowInputModal(true)
    }
  }, [token])

  // ルーム開閉状態による自動制御
  useEffect(() => {
    const handleRoomStateChange = () => {
      try {
        if(LocalStorage.roomClosed) {
          // ルームが開始されていない状態・終了した状態の場合は音声レコーダー停止（クリック不可）
          recordRef.current && recordRef.current.stopRecording(true);
        } else{
          // ルームが開始されたらクリック可能とする
          recordRef.current && recordRef.current.enableToggle();
          // 言語設定済・自動的にマイクをオンにする設定の場合は音声レコーダー開始
          if (token && !summaryFlag && LocalStorage.autoStart && LocalStorage.language && LocalStorage.name) {
            recordRef.current && recordRef.current.startRecording();
          }
        }
      } catch (e: any) {
        console.error('An error occurred while processing the room state change', e)
        sentryLog(e)
      }
    };
    // ローカルストレージのデータが変更された際に発火するリスナーとして追加
    window.addEventListener('localstorage-change-roomState', handleRoomStateChange);

    return () => {
      // リスナー解除
      window.removeEventListener('localstorage-change-roomState', handleRoomStateChange);
    };
  }, [summaryFlag, token]);

  // 音声レコーダー・ボタンの変化があったら発火する
  const onRecordStateChange = useCallback(({isRecording, isDisabled}: {isRecording: boolean, isDisabled: boolean}) => {
    setRecordValues({isRecording, isDisabled});
  },[]);

  // 他のクラスに音声レコーダーの更新を伝播させる
  useEffect(() => {
    if (recordRef.current) {
      AudioManager.setRecordRef(recordRef);
      AdvisorStateInst.setRecordRef(recordRef);
    }
  }, [recordValues]);

  const name = LocalStorage.name
  // update name to server
  useEffect(() => {
    if (name) {
      changeName(name)
      if (LocalStorage.uid) {
        Messages.changeName(LocalStorage.uid, name)
      }
    }
  }, [changeName, name])

  useEffect(() => {
    if (LocalStorage.uid != null && token !== null){
      const meetingId = token as string
      sentrySetUser(LocalStorage.uid, meetingId)
    }
  }, [token])

  useEffect(() => {
    // 自身のclientIdを持つ参加者をTTSに報告する人数とする
    const filtered = participants.filter(p => p.clientId === (LocalStorage.uid || USER_ID));
    recordRef.current && recordRef.current.updateClusterAmount(filtered.length);
  }, [participants])

  useEffect(() => {
    if(roomOwnerId === LocalStorage.ownerId){
      setIsOwner(true);
    }
  }, [roomOwnerId]);

  // const { onStart, onStop, sendData } = useGoogleSpeech({
  //   userId: USER_ID,
  //   onMessage: (result, isFinal) => {
  //     console.log(result)
  //   },
  //   needInterim: false,
  // })

  // const handleMicData = useCallback((data: BlobPart) => {
  //   sendData(data)
  // }, [sendData])

  // const handleStartRecording = useCallback(() => {
  //   onStart(LanguageList['ja-JP'].id)
  // }, [onStart])

  const handleMicData = useCallback(async (talkId: string, speakerId: string, text: string, language: string, isFinal: boolean = false) => {
    if (LocalStorage.roomClosed) {
      return
    }

    const msgText = text.trim()

    // ドラフト文の場合はローディング表示
    if(!isFinal){
      setShowLoadingChat(true);
      return;
    }else{
      // 最終結果の場合は非表示
      setShowLoadingChat(false);
    }

    // 最終結果かつ空文字である場合は処理しない
    if (msgText === '') {
      return;
    }

    const messageId = randomId()
    const languageID = guestLanguage(language)
    const languageCode = (
      languageID?.id ?? LocalStorage.language[0] ?? ILanguages['ja-JP']
    )
    const speaker: Speaker = {
      id: `${LocalStorage.uid}/${speakerId}`,
      userId: null
    }

    sendMessage(msgText, messageId, speaker.id, talkId, languageCode)

    // Add speaker to firestore.

    await addOrUpdateSpeaker(roomOwnerId, meetingId, speaker.id, speaker.userId)

    Messages.add({
      id: messageId,
      text: msgText,
      talkLabel: talkId,
      userId: LocalStorage.uid || USER_ID,
      speakerId: speaker.id,
      sourceLanguage: languageCode,
      translations: {},
    })

    if (languageID) {
      try {
        if (process.env.REACT_APP_ENVIRONMENT === "production") {
          // 本番環境の場合は既存機能（Google翻訳API）を使用
          await addTranslation(msgText, languageID.id, async (toLanguage: ILanguages, translatedText: string) => {
            Messages.addTranslation(messageId, toLanguage, translatedText)
            sendMessage(translatedText, messageId, speaker.id, talkId, toLanguage)

            if (LocalStorage.speakTranslateLocal) {
              AudioManager.queuePlayAudio(
                  textToSpeech(translatedText, toLanguage),
                  audioMasterAnalyserBus
              )
            }
          })
        } else {
          // 開発環境・ステージング環境ではLLMの翻訳を試す
          // 左（主要言語）
          const left = !LocalStorage.language || LocalStorage.language.length === 0 ? [ILanguages["ja-JP"]] : LocalStorage.language;
          // 右（翻訳言語）
          const right = !LocalStorage.translationLanguage ? ILanguages["ja-JP"] : LocalStorage.translationLanguage;
          // 言語を統合
          let langs = [...left];
          if (!left.includes(right)) {
            langs.push(right)
          }
          // 文字起こしされた言語を除外
          langs = langs.filter(l => l !== languageCode);
          if (langs.length !== 0) {
            createTrans(messageId, msgText, langs);
          }
        }
      }catch (e: any) {
        console.error('Error during translation or speech synthesis:', e);
        sentryLog(e);
      }
    }
  }, [sendMessage, createTrans, roomOwnerId, meetingId])

  const showLanguageSelection = useCallback(() => {
    setExpandLanguageList(true)
    setShowInputModal(true)
  }, [])

  const handleInputModalClose = useCallback(() => {
    setExpandLanguageList(false)
    setShowInputModal(false)
    setDoUserOperation(true);
    try {
      if (token && !summaryFlag && LocalStorage.autoStart && LocalStorage.language && LocalStorage.name && !LocalStorage.roomClosed && !AdvisorStateInst.isRunning) {
        recordRef.current && recordRef.current.startRecording()
      }else if(LocalStorage.roomClosed){
        recordRef.current && recordRef.current.stopRecording(true)
      }
    } catch (e: any) {
      sentryLog(e);
    }
  }, [token, summaryFlag])

  const handleTroubleshootingModalShow = useCallback(() => {
    setShowTroubleshootingModal(true)
  }, [])

  const handleTroubleshootingModalClose = useCallback(() => {
    setShowTroubleshootingModal(false)
  }, [])

  const handleMeetingFinishModalClose = useCallback((action: MEETING_FINISH_MODAL_ACTION) => {
    setShowMeetingFinishModal(false)
    switch (action){
      case MEETING_FINISH_MODAL_ACTION.leave:
        window.close();
        // javascriptで画面を開いていない場合 画面が閉じられないためブランク画面に移動する
        window.open('about:blank', '_self');
        break;
      case MEETING_FINISH_MODAL_ACTION.end:
        roomClose();
        window.close();
        // javascriptで画面を開いていない場合 画面が閉じられないためブランク画面に移動する
        window.open('about:blank', '_self');
        break;
    }
  }, [roomClose])

  const handleConfirmMeetingActiveModalClose = useCallback((close: boolean) => {
    setShowConfirmMeetingActiveModal(false)
    if(close){
      roomClose();
    }
  }, [roomClose])

  ConfirmMeetingActiveModalStateInst.callback = () => {
    setShowConfirmMeetingActiveModal(true);
    if(timerRef.current){
      // 既存のタイマーをクリア
      // @ts-ignore
      clearTimeout(timerRef.current);
    }

    // 新しいタイマーを設定
    // @ts-ignore
    timerRef.current = setTimeout(() => {
      if (ConfirmMeetingActiveModalStateInst.isShow) {
        setShowConfirmMeetingActiveModal(false);
        roomClose();
      }
    }, 60000);
  }

  useEffect(() => {
    ConfirmMeetingActiveModalStateInst.isShow = showConfirmMeetingActiveModal;
  }, [showConfirmMeetingActiveModal]);

  const initiateAdvisory = useCallback(async () => {
    if(AdvisorStateInst.isRunning){
      stopAdvisory();
    }else{
      setShowConfirmAIAdvisorModal(true);
    }
  }, [stopAdvisory]);

  const handleConfirmAIAdvisorModalClose = useCallback((show: boolean) => {
    setShowConfirmAIAdvisorModal(false);
    if (LocalStorage.roomClosed) {
      return;
    }
    if(show){
      initAdvisor()
    }
  }, [initAdvisor])

  const handleSummaryWindowShow = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.set(paramSummaryWindow, '1');
    openWindow(url.toString());
  }

  const openWindow = (url: string, width: number = 600, height: number = 600) => {
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);
    window.open(
        url,'Window', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  }

  const handleExitBtnClick = () => {
    if(isOwner){
      setShowMeetingFinishModal(true);
    }else{
      window.close();
      // javascriptで画面を開いていない場合 画面が閉じられないためブランク画面に移動する
      window.open('about:blank', '_self');
    }
  }

  useEffect(() => {
    // Collapseを生成（生成されている場合は再生成しない）
    if(!bsCollapseChatContentRef.current){
      bsCollapseChatContentRef.current = new window.bootstrap.Collapse(chatContentRef.current, {
        toggle: false,
      });
    }
  }, []);

  useEffect(() => {
    // Collapseを生成（生成されている場合は再生成しない）
    if(!bsCollapseAdvisorContentRef.current){
      bsCollapseAdvisorContentRef.current = new window.bootstrap.Collapse(advisorContentRef.current, {
        toggle: false,
      });
    }
  }, []);

  const toggleContentVisibility = () => {
    if (isShowChatContent) {
      showAdvisorContent();
    } else {
      showChatContent();
    }
  };

  const showChatContent = () => {
    bsCollapseChatContentRef.current.show();
    bsCollapseAdvisorContentRef.current.hide();
    setIsShowChatContent(true);
    setIsEnabled(true);
  };

  const showAdvisorContent = () => {
    bsCollapseChatContentRef.current.hide();
    bsCollapseAdvisorContentRef.current.show();
    setIsShowChatContent(false);
    setIsEnabled(false);
  };

  const onAdvisorMessageSubmit = useCallback((content: string) => {
    const userMessage = advisorMessages.addUserMessage(content);
    const message = advisorMessages.initialAdvisorMessage(userMessage.id);
    try {
      advisorChatRequest(message.id, content);
      setShowLoadingAdvisorChat(true);
    } catch (e: any) {
      sentryLog(e);
      alert(t('会議AIアシスタントへのメッセージを送信中にエラーが発生しました'));
    }
  }, [advisorChatRequest, t]);

  useEffect(() => {
    if(advisorMessages.length() === 0){
      advisorMessages.addMessage('assistant', t('こんにちは! donutAIです。\n会議の内容などについて、お気軽に質問してくださいね。'), true);
    }
  }, [t, onAdvisorMessageSubmit]);

  advisorMessages.beforeStreamMessage = () => {
    setShowLoadingAdvisorChat(false);
  }

  const roomStateMessages = {
    waiting: t('開始されるのを待っています'),
    completed: t('会議が終了されました'),
    notexist: t('その会議は存在しません'),
    connecting: null,
    incall: null,
  };

  const handleShowSttPrompt = () => {
    setShowSttChangeModal(true);
  }

  const handleSttChangeModalClose = useCallback(() => {
    setShowSttChangeModal(false);
  }, [])

  const updateLanguage = useCallback( async () => {
    await updateUserLanguage(roomOwnerId, meetingId);
  }, [meetingId, roomOwnerId]);

  useEffect(() => {
    if (roomOwnerId === INITIAL_ID || meetingId === INITIAL_ID) {
      return;
    }

    (async() => { await updateUserLanguage(roomOwnerId, meetingId) })();

    // ローカルストレージのデータが変更された際に発火するリスナーとして追加
    window.addEventListener('localstorage-change-language', updateLanguage);
    window.addEventListener('localstorage-change-translationLanguage', updateLanguage);

    return () => {
      // リスナー解除
      window.removeEventListener('localstorage-change-language', updateLanguage);
      window.removeEventListener('localstorage-change-translationLanguage', updateLanguage);
    };
  }, [meetingId, roomOwnerId, updateLanguage]);

  return (
    <div className={`${summaryFlag ? 'SummaryOnly' : ''}`}>
      <div className='main-page'>
        {(!summaryFlag && isNotProduction()) && (
          <div style={{ position: 'absolute', top: 10, left: 10 }}>
            <button className='btn btn-danger btn-sm' onClick={handleShowSttPrompt}>
            {t('STT変更')}
            </button>
          </div>
        )}
        <div className='logo-bg'>
          <img src='/logo.png' alt='LOGO' />
        </div>

        <section className='w-100'>
          <div className='row justify-content-center'>
            <Header
              onLeftFlagClick={showLanguageSelection}
              onRightFlagClick={() => setOpenTranslatingModal(true)}
              onClickCrystal={initiateAdvisory}
              stopAdvisory={stopAdvisory}
              enableAIAdvisor={enableAIAdvisor}
              doUserOperation={doUserOperation}
            />
          </div>

          <div className='row justify-content-center flex-fill main-wrapper'>
            <section className='box-left col-2'>
              <h4 className='my-4 text-center'>{t('参加者一覧')}</h4>
              <div className='users-list-bubble'>
                <PerticipantsWidget participants={participants} roomOwnerId={roomOwnerIdRef.current} meetingId={meetingIdRef.current} />
              </div>
              <button type="button" className="btn btn-troubleshooting w-100 mt-2" onClick={handleTroubleshootingModalShow}>{t('トラブルシューティング')}</button>
            </section>

            <section className={`col-8 main-container ${summaryFlag && 'd-none'}`}>
              {roomStateMessages[LocalStorage.roomState] && (
                  <div className='box-01 no-token'>
                    <h1 className='text-secondary text-center'>
                      {roomStateMessages[LocalStorage.roomState]}
                    </h1>
                  </div>
              )}

              <div className={`box-01 accordion${LocalStorage.roomState !== 'incall' ? ' d-none' : ''}`}>
                <div className="accordion-item">
                  <div
                      id="chat-content"
                      ref={chatContentRef}
                      className={`accordion-collapse collapse collapse show`}
                  >
                    <div className="accordion-body">
                      <ChatList
                          items={Array.from(Messages.data.values())}
                          userId={LocalStorage.uid || USER_ID}
                          roomOwnerId={roomOwnerIdRef.current}
                          meetingId={meetingIdRef.current}
                          showLoadingChat={showLoadingChat}
                      />
                    </div>
                  </div>
                  <button
                      className={`btn btn-chat-switch w-100${isShowChatContent ? ' show-chat' : ' show-advisor'}`}
                      type="button"
                      onClick={toggleContentVisibility}
                      aria-controls="chat-content advisor-content"
                  >
                    {isShowChatContent ? (<CaretUpFill className="caret-up-fill" />) : (
                        <CaretDownFill className="caret-down-fill"/>)}
                  </button>
                  <div
                      id="advisor-content"
                      ref={advisorContentRef}
                      className={`accordion-collapse collapse`}
                  >
                    <div className="accordion-body">
                      <AdvisorContent
                          items={Array.from(advisorMessages.data.values())}
                          showLoadingChat={showLoadingAdvisorChat}
                          retryMessage={onAdvisorMessageSubmit}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`footer-input-form${LocalStorage.roomState !== 'incall' ? ' d-none' : ''}`}>
                <AdvisorForm
                    token={token}
                    showAdvisorContent={showAdvisorContent}
                    onAdvisorMessageSubmit={onAdvisorMessageSubmit}/>
              </div>
            </section>

            <section className='box-summary box-right col-2'>
              <h4 className='my-4 text-center'>{t('現在の進捗')}<WindowStack className="window-stack text-primary" onClick={handleSummaryWindowShow}/></h4>
              <CurrentProgress
                roomOwnerId={roomOwnerId}
                meetingId={meetingId}
                advisorStateInstance={AdvisorStateInst}
                agendaAdd={agendaAdd}
                agendaEdit={agendaEdit}
                agendaDelete={agendaDelete}
                agendaChange={agendaChange} />
            </section>
          </div>

          <div className='row justify-content-center'>
            {!!token && meetingId !== INITIAL_ID && !LocalStorage.roomClosed && !summaryFlag && (
              <div className='bottom-bar flex-fill'>
                <RecordButton
                  meetingId={meetingId}
                  onData={handleMicData}
                  ref={recordRef}
                  onRecordStateChange={onRecordStateChange}
                  changeLabels={changeLabels}
                  roomOwnerId={roomOwnerId}
                />
              </div>
            )}
          </div>
          {!LocalStorage.roomClosed && !summaryFlag && (
              <button className='btn btn-danger btn-room-close' onClick={handleExitBtnClick}>{t('終了')}</button>
          )}
        </section>
      </div>
      {isNotProduction() && (<SttChangeModal open={showSttChangeModal} onClose={handleSttChangeModalClose} />)}
      <ConfirmAIAdvisorModal open={showConfirmAIAdvisorModal} onClose={handleConfirmAIAdvisorModalClose} />
      <ConfirmMeetingActiveModal open={showConfirmMeetingActiveModal} onClose={handleConfirmMeetingActiveModalClose} />
      <MeetingFinishModal open={showMeetingFinishModal} onClose={handleMeetingFinishModalClose} />
      <TroubleshootingModal open={showTroubleshootingModal} onClose={handleTroubleshootingModalClose} />
      <UserInfoModal open={!AdvisorStateInst.isRunning && showInputModal} languageListOpen={expandLanguageList} onClose={handleInputModalClose} />
      <SelfTranslateModal open={!AdvisorStateInst.isRunning && openTranslatingModal} lang={LocalStorage.translationLanguage} onSelect={handleSelfTranslatingSelection} />
      <iframe src={`${process.env.REACT_APP_CLIPEAR_USER_MANGEMENT_URL}?${paramShareWindow}=1`} title='CLIPEAR_USER_MANGEMENT_URL' className='d-none'/>
      <ReactHotToast/>
    </div>
  )
})
