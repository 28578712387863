import './ChatRow.css'
import { useEffect, useState } from "react";

const LoadingChatRow = ({mine, autoHidden = true}: {mine: boolean, autoHidden?: boolean}) => {
  const [showLoadingChatRow, setShowLoadingChatRow] = useState(true);
  const [loadingDotsCount, setLoadingDotsCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // ドットの数を1〜4つに変化させている
      // 4つを超えるとリセットされる
      setLoadingDotsCount(prev => {
        if (prev < 4) {
          return prev + 1;
        } else {
          return 0;
        }
      });
    }, 250);

    return () => clearInterval(interval);
  }, []);

  // 10秒後に吹き出しを <自動的> に非表示にする
  const hideLoadingChatRowAfterMs = 10000;
  useEffect(() => {
    const timeout = setTimeout(() => {
      if(autoHidden){
        setShowLoadingChatRow(false);
      }
    }, hideLoadingChatRowAfterMs);

    return () => clearTimeout(timeout);
  }, [autoHidden]);

  // 非表示にする場合はレンダリングしない
  if (!showLoadingChatRow) {
    return null;
  }

  return (
    <div style={{ width: 'auto' }} className={`chat-row ${mine ? 'mine': 'other'} chat-row-draft row justify-content-${mine ? 'end': 'start'}`}>
      <div className='col-auto flex-shrink-1'>
        <div className='chat-text-container'>
          <div className='chat-text'>
            <p className='text-black' style={{ width: '4em', height: '0.5em' }}>
              {[...Array(loadingDotsCount)].map((_, index) => (
                  <span key={index} className="dot">・</span>
              ))}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingChatRow
