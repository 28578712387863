import type { Socket } from 'socket.io-client'
import { io } from 'socket.io-client'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import useQuery from '../hook/useQuery';
import toast from 'react-hot-toast';

import { LocalStorage } from '../store/LocalStorage'
import { addTranslation, translate } from '../api/GoogleApi';
import { ILanguages } from '../util/language'
import Messages from '../store/Messages'
import { INITIAL_ID, SYSTEM_USER_ID, htmlDecode, changeLanguageAgenda, changeLanguageMinutes } from '../util/util'
import { AdvisorState, RealtimeMinute } from '../store/AdvisorState';
import ConfirmMeetingActiveModalState from '../store/ConfirmMeetingActiveModalState'
import { sentryLog } from "../util/sentry";
import advisorMessages from "../store/AdvisorMessages";
import AudioManager from '../store/AudioManager'
import { audioMasterAnalyserBus } from '../util/audioContext'
import { textToSpeech } from "../util/TTS";

const MT_EVENTS = {
  ROOM_CLOSED: 'roomClosed',
  ROOM_BEGIN:  'roomBegin',
  ROOM_ID: 'roomId',
  ROOM_CLOSE: 'roomClose',
  JOIN_ROOM: 'joinRoom',
  CHANGE_NAME: 'changeName',
  CHANGE_LABELS: 'changeLabels',
  MEMBERS: 'members',
  SEND_MSG: 'sendMessage',
  RECEIVE_MSG: 'receiveMessage',
  TRANS: 'translation',
  CREATE_TRANS: 'createTranslation',
  ADD_MEM: 'onAddMember',
  NEW_MEM: 'newMeetingUser',
  CONNECT: 'connection',
  DISCONNECT: 'disconnect',
  INITIATE_ADVISORY: 'initiateAdvisory',
  STOP_ADVISORY: 'stopAdvisory',
  BROADCAST_ADVISORY: 'broadcastAdvisory',
  BROADCAST_ADVISORY_ACCURACY: 'broadcastAdvisoryAccuracy',
  BROADCAST_STOP_ADVISORY: 'broadcastStopAdvisory',
  REALTIME_UPDATE: 'realtimeUpdate',
  AGENDA_ADD: 'agendaAdd',
  AGENDA_EDIT: 'agendaEdit',
  AGENDA_DELETE: 'agendaDelete',
  AGENDA_CHANGE: 'agendaChange',
  ALERT: 'alert',
  CONFIRM_MEETING_ACTIVE: 'confirmMeetingActive',
  ADVISOR_CHAT_REQUEST: 'advisorChatRequest',
  ADVISOR_CHAT_RESPONSE: 'advisorChatResponse',
  ADVISOR_CHAT_RESPONSE_ERROR: 'advisorChatResponseError',
} as const

const ADVISOR_EVENT = {
  INIT: '[$init]',
  END:  '[$end]',
} as const

export type AssistantMessageSteamResponse = {
  finish: boolean;
  delta: string;
  snapshot: string;
  event: 'on_text_delta' | 'on_tool_call_created' | 'on_tool_call_done';
};


export const useChat = (advisorStateInstance: AdvisorState, auth?: string | null) => {
  const { t } = useTranslation()
  const [members, setMembers] = useState<{ [id: string]: ChatUser }>({})
  const [name, setName] = useState<string>(LocalStorage.name ?? '');

  // get room id back from server after jwt token verified
  const roomOwnerIdRef = useRef(INITIAL_ID)
  const meetingIdRef = useRef(INITIAL_ID)
  const enableAIAdvisorRef = useRef(false)
  const displayedErrorMessageRef = useRef(false)

  const membersRef = useRef(members)
  useEffect(() => {
    membersRef.current = members
  }, [members])

  const socketRef = useRef<Socket>()

  const { getSummaryFlag } = useQuery();
  const summaryFlag = getSummaryFlag();

  // ルーム開閉状態による自動制御
  useEffect(() => {
    const handleNameChange = () => {
      if(LocalStorage.name && LocalStorage.name !== ''){
        setName(LocalStorage.name);
      }
    };
    // ローカルストレージのデータが変更された際に発火するリスナーとして追加
    window.addEventListener('localstorage-change-name', handleNameChange);

    return () => {
      // リスナー解除
      window.removeEventListener('localstorage-change-name', handleNameChange);
    };
  }, []);

  useEffect(() => {
    if (LocalStorage.uid && name && auth && meetingIdRef.current) {
      if(!socketRef.current){
        console.log('create socket')
        socketRef.current = io(process.env.REACT_APP_BASE_URL!.replace('/api', '/mt'), {
          rejectUnauthorized: true,
          secure: true,
          transports: ['websocket'],
          reconnection: true,
          auth: {
            token: auth,
            sender: {
              id: LocalStorage.uid,
              displayName: LocalStorage.name,
            },
          },
        })
      }

      socketRef.current.on('connect', () => {
        LocalStorage.setValue('roomState', 'waiting')
        console.log('Connected', socketRef.current?.id)
        // socketRef.current.emit(MT_EVENTS.JOIN_ROOM, meetingIdRef.current)
      }).on(MT_EVENTS.ROOM_ID, (roomId: string, roomOwnerId: string, agenda: string, minutes: RealtimeMinute[], enableAIAdvisor: boolean) => {
        meetingIdRef.current = roomId
        roomOwnerIdRef.current = roomOwnerId;
        enableAIAdvisorRef.current = enableAIAdvisor; // AIアドバイザー機能を使用するかどうか（「お話してもよろしいですか？」を表示するかどうか）
        socketRef.current?.emit(MT_EVENTS.JOIN_ROOM, meetingIdRef.current)
        console.log('realtime init', minutes);
        const language = LocalStorage?.language[0] || ILanguages['ja-JP'];
        if(minutes && minutes.length !== 0){
          advisorStateInstance.updateMinute(changeLanguageMinutes(language, minutes), changeLanguageAgenda(language, agenda));
        }
      }).on('connect_error', (error: any) => {
        if(error?.message === 'User not authenticated'){
          LocalStorage.setValue('roomState', 'notexist')
          return;
        }
        if (error?.message === 'code:400') {
          LocalStorage.setValue('roomState', 'completed')
        } else {
          console.warn('Unhandled socket error', error)
          if(!displayedErrorMessageRef.current){
            displayedErrorMessageRef.current = true;
            alert(t('サーバーと接続できません。しばらくお待ち下さい。'));
            sentryLog(error)
          }
        }
      }).on("connect_failed", (error : any) => {
        sentryLog(error)
      }).on(MT_EVENTS.DISCONNECT, () => {
        console.log('Disconnected', socketRef.current?.id)
      }).on(MT_EVENTS.NEW_MEM, (data: any) => {
        const newUser: ChatUser = JSON.parse(data)
        // addNewUser(newUser)
        console.log(MT_EVENTS.NEW_MEM, newUser)
      }).on(MT_EVENTS.MEMBERS, (data: any) => {
        setMembers(JSON.parse(data))
      }).on(MT_EVENTS.RECEIVE_MSG, async (data: any) => {
        const message: ChatMessage = data
        if (!message.translations) {
          message.translations = {}
        }
        // guard agains empty message
        if (!message.text) {
          return
        }
        if(summaryFlag) {
          return
        }

        const sourceLanguage    = message.sourceLanguage as ILanguages
        const primaryLanguage = !LocalStorage.language || LocalStorage.language.length === 0 ? [ILanguages["ja-JP"]] : LocalStorage.language;

        if (sourceLanguage && !primaryLanguage.includes(sourceLanguage)) {
          if (process.env.REACT_APP_ENVIRONMENT === "production") {
            // 本番環境の場合は既存機能（Google翻訳API）を使用
            const sourceToPrimary = htmlDecode(await translate(message.text, sourceLanguage, primaryLanguage[0]))
            message.translatedLang = primaryLanguage[0]
            message.translated = sourceToPrimary

            if (sourceToPrimary && message.userId !== SYSTEM_USER_ID) {
              socketRef.current?.emit(MT_EVENTS.TRANS, message.id, sourceToPrimary, primaryLanguage)
            }
          }
        }

        Messages.add(message)

        if(sourceLanguage !== null){
          if (process.env.REACT_APP_ENVIRONMENT === "production") {
            // 本番環境の場合は既存機能（Google翻訳API）を使用
            await addTranslation(message.text, sourceLanguage, async (toLanguage: ILanguages, translatedText: string) => {
              Messages.addTranslation(message.id, toLanguage, translatedText);
            })
          }
        }

        if (process.env.REACT_APP_ENVIRONMENT !== "production") {
          // 開発環境・ステージング環境ではLLMの翻訳を試す
          // 左（主要言語）
          const left = !LocalStorage.language || LocalStorage.language.length === 0 ? [ILanguages["ja-JP"]] : LocalStorage.language;
          // 右（翻訳言語）
          const right = !LocalStorage.translationLanguage ? ILanguages["ja-JP"] : LocalStorage.translationLanguage;
          // 言語を統合
          let langs = [...left];
          if (!left.includes(right)) {
            langs.push(right)
          }
          // 文字起こしされた言語を除外
          langs = langs.filter(l => l !== sourceLanguage);
          if (langs.length !== 0) {
            socketRef.current?.emit(MT_EVENTS.CREATE_TRANS, message.id, message.text, langs);
          }
        }

      }).on(MT_EVENTS.TRANS, (id: string, message: string, lang: string, mine: boolean = false) => {
        if (mine && LocalStorage.speakTranslateLocal) {
          AudioManager.queuePlayAudio(
              textToSpeech(message, lang as ILanguages),
              audioMasterAnalyserBus
          )
        }
        Messages.addTranslation(id, lang, message)
      }).on(MT_EVENTS.CHANGE_NAME, (userId, newName) => {
        Messages.changeName(userId, newName)
      }).on(MT_EVENTS.CHANGE_LABELS, (userId: string, idMap: { [id: string]: string }) => {
        Messages.changeLabel(userId, idMap)
      }).on(MT_EVENTS.ROOM_BEGIN,  (roomInfo) => {
        console.log("Received `ROOM_BEGIN`");
        LocalStorage.setValue('roomState', 'incall')
        LocalStorage.setValue('endTime', new Date(roomInfo.endTime))
      }).on(MT_EVENTS.ROOM_CLOSED, () => {
        console.log("Received `ROOM_CLOSED`");
        LocalStorage.setValue('roomState', 'completed')
        socketRef.current?.disconnect()
      }).on(MT_EVENTS.BROADCAST_ADVISORY, (id, message) => {
        if(summaryFlag) {
          return
        }
        switch(message) {
          case ADVISOR_EVENT.INIT:
            advisorStateInstance.initialize(id); break;
          case ADVISOR_EVENT.END:
            advisorStateInstance.finish(id); break;
          default:
            advisorStateInstance.feed(id, message); break;
        }
      }).on(MT_EVENTS.BROADCAST_STOP_ADVISORY, () => {
        try {
          advisorStateInstance.stop();
        }catch (error: any){
          sentryLog(error);
        }
      }).on(MT_EVENTS.BROADCAST_ADVISORY_ACCURACY, (id, message) => {
        if(summaryFlag) {
          return
        }
        switch(message) {
          case ADVISOR_EVENT.INIT:
            break;
          case ADVISOR_EVENT.END:
            advisorStateInstance.llmAdvisorMessage = '';
            break;
          default:
            advisorStateInstance.llmAdvisorMessage = message;
            break;
        }
      }).on(MT_EVENTS.REALTIME_UPDATE, (agenda: string | null, minutes: RealtimeMinute[]) => {
        console.log('realtime update', agenda, minutes);
        const language = LocalStorage?.language[0] || ILanguages['ja-JP'];
        if(minutes && minutes.length !== 0){
          let changeAgenda = agenda;
          if(agenda){
            changeAgenda = changeLanguageAgenda(language, agenda);
          }
          advisorStateInstance.updateMinute(changeLanguageMinutes(language, minutes), changeAgenda);
        }
      }).on(MT_EVENTS.ALERT, (message: string) => {
        if(LocalStorage.roomClosed){
          return;
        }
        alert(t(message));
      }).on(MT_EVENTS.CONFIRM_MEETING_ACTIVE, () => {
        // 会議を継続するかを尋ねる
        ConfirmMeetingActiveModalState.callback();
      }).on(MT_EVENTS.ADVISOR_CHAT_RESPONSE, (id: string, response: AssistantMessageSteamResponse) => {
        // 会議が終了している場合は処理しない
        if(LocalStorage.roomClosed){
          return;
        }
        // IDが存在しない場合は処理しない
        if(!advisorMessages.existMessage(id)){
          return;
        }
        // メッセージを更新
        advisorMessages.beforeStreamMessage();
        advisorMessages.updateUnfinishedMessage(id, response.snapshot, response.finish);
      }).on(MT_EVENTS.ADVISOR_CHAT_RESPONSE_ERROR, (id: string) => {
        // 会議が終了している場合は処理しない
        if(LocalStorage.roomClosed){
          return;
        }
        // IDが存在しない場合は処理しない
        if(!advisorMessages.existMessage(id)){
          return;
        }
        // メッセージを更新
        advisorMessages.beforeStreamMessage();
        advisorMessages.updateUnfinishedMessage(id, "", true, true);
        const toastId = toast(<div onClick={() => toast.dismiss(toastId)} style={{ cursor: 'pointer' }}>{t('メッセージの送信に失敗しました。')}<br/>{t('サーバーが混み合っている可能性があります。しばらく経ってから送信してください。')}</div>, {
          duration: 10000,
          position: 'top-right',
          icon: '⚠️',
        });
      });

      return () => {
        try {
          if(socketRef.current){
            socketRef.current.off()
            // socketRef.current.off('connect')
            // socketRef.current.off('disconnect')
            // socketRef.current.off('newMeetingUser')
            // socketRef.current.off('members')
            // socketRef.current.off('receiveMessage')
          }
        } catch (e) {
          // ignored
        }
      }
    }
  }, [t, name, auth, summaryFlag, advisorStateInstance])

  const memoHandles = useMemo(() => {
    return {
      sendMessage: (message: string, id: string, speakerId: string, talkLabel: string, language?: ILanguages) => {
        try {
          socketRef.current?.emit(MT_EVENTS.SEND_MSG, { id, message },
              meetingIdRef.current,
              speakerId,
              talkLabel,
              language
          );
        }catch (error: any){
          sentryLog(error);
        }
      },
      createTrans: (id: string, message: string, translate_languages: ILanguages[]) => {
        try {
          socketRef.current?.emit(MT_EVENTS.CREATE_TRANS, id, message, translate_languages);
        }catch (error: any){
          sentryLog(error);
        }
      },
      changeName:  (name: string) => {
        socketRef.current?.emit(MT_EVENTS.CHANGE_NAME, name);
      },
      changeLabels:  (idMap: { [id: string]: string }) => {
        socketRef.current?.emit(MT_EVENTS.CHANGE_LABELS, idMap);
      },
      agendaAdd:  (val: string) => {
        socketRef.current?.emit(MT_EVENTS.AGENDA_ADD, val);
      },
      agendaEdit:  (val: string, targetAgenda: string) => {
        socketRef.current?.emit(MT_EVENTS.AGENDA_EDIT, val, targetAgenda);
      },
      agendaDelete:  (targetAgenda: string) => {
        socketRef.current?.emit(MT_EVENTS.AGENDA_DELETE, targetAgenda);
      },
      agendaChange:  (targetAgenda: string) => {
        socketRef.current?.emit(MT_EVENTS.AGENDA_CHANGE, changeLanguageAgenda(ILanguages['ja-JP'], targetAgenda));
      },
      initiateAdvisory: () => {
        socketRef.current?.emit(MT_EVENTS.INITIATE_ADVISORY, LocalStorage.language[0], advisorStateInstance.llmAdvisorMessage);
        advisorStateInstance.llmAdvisorMessage = ''
      },
      stopAdvisory: () => {
        try {
          socketRef.current?.emit(MT_EVENTS.STOP_ADVISORY);
        }catch (error: any){
          sentryLog(error);
        }
      },
      roomClose:  () => {
        socketRef.current?.emit(MT_EVENTS.ROOM_CLOSE);
      },
      advisorChatRequest: (id: string, content: string) => {
        socketRef.current?.emit(MT_EVENTS.ADVISOR_CHAT_REQUEST, id, content, LocalStorage.language[0]);
      }
    };
  }, [advisorStateInstance]);

  return {
    ...memoHandles,
    meetingIdRef,
    roomOwnerIdRef,
    enableAIAdvisorRef,
  }
}
