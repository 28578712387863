import './ChatRow.css'

import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import UserAvatar from './UserAvatar'
import Oscilloscope from './Oscilloscope'
import { InlineFlag } from './InlineFlag'
import { LocalStorage } from '../store/LocalStorage'
import AdvisorState from '../store/AdvisorState'
import { ILanguages } from '../util/language'
import { useSpeakers, addOrUpdateSpeaker  } from '../hook/useSpeakers'
import { useParticipants  } from '../hook/useParticipants'
import { Participant } from '../types/participant'
import { useTranslation } from "react-i18next";
import { SYSTEM_USER_ID } from '../util/util';
import { CHAT_MESSAGE_UPDATED_EVENT, DispatchEvent } from "../util/eventListener";

interface ChatRowProps {
  user: string | number;
  item: ChatMessage;
  roomOwnerId: string;
  meetingId: string;
}

const MainText = observer(({ chat }: { chat: ChatMessage }) => {
  return (
    <span className='text-black'>
      {chat.text}
    </span>
  )
})

const TranslatedText = observer(({ chat, language }: { chat: ChatMessage, language: ILanguages }) => {
  if (chat.sourceLanguage === language || (chat.translatedLang == null && chat.translations == null)) {
    return null
  }

  let languageFlag: ILanguages | null = null
  let message: string | null = null

  if (chat.translated != null && chat.translatedLang === language) {
    message = chat.translated
    languageFlag = chat.translatedLang
  } else if (chat.translations[language] != null) {
    message = chat.translations[language]
    languageFlag = language
  }

  if (message == null || languageFlag == null) {
    return null
  }

  return (
    <span key={language}>
      <br />
      <InlineFlag lang={languageFlag} />
      <span className='original-text text-dark'>
        {message}
      </span>
    </span>
  )
})

const SpeakerName = ({ roomOwnerId, meetingId, displayName, participants, item, isMine}: {roomOwnerId: string, meetingId: string, displayName: string, participants: Participant[], item: ChatMessage, isMine: boolean}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);
  const selectableParticipants = participants.filter(participant => participant.state === "ONLINE" && participant.clientId === item.userId);

  const handleNameSelect = async (userId: string) => {
    setDropdownOpen(false);
    if (item.speakerId) {
      await addOrUpdateSpeaker(roomOwnerId, meetingId, item.speakerId, userId);
    }
  };

  return (
      <div className='ms-1' onClick={() => {isMine && toggleDropdown()}}>
        <small>{displayName}</small>
        {isMine && isDropdownOpen && (
          <div className="name-dropdown">
            {selectableParticipants.map((participant, index) => (
              participant.state === "ONLINE" ? <div key={index} onClick={() => handleNameSelect(participant.id)} className="dropdown-item">
                {participant.name}
              </div> : <></>
            ))}
          </div>
        )}
      </div>
  )
}

const ChatRow: React.FC<ChatRowProps> = ({ user, item, roomOwnerId, meetingId  }) => {
  const { t } = useTranslation()

  const [isAddedUserExist, setIsAddedUserExist] = useState(false);
  const [displayName, setDisplayName] = useState('');

  const participants = useParticipants(roomOwnerId, meetingId);
  const speakers = useSpeakers(roomOwnerId, meetingId);

  // 話し中のアドバイザーのメッセージかどうか確認
  const isActiveAdvisor = AdvisorState.messageId === item.id;
  // 話し中のアドバイザーのメッセージであり、かつ読み込み中かどうか確認
  const isLoading = isActiveAdvisor && AdvisorState.isLoading;

  // 区切り記号
  const separateString = '/';
  // 自身のメッセージかどうか確認
  const isMine = user === item.userId;
  // システムメッセージかどうか確認
  const isSystem = item.userId === SYSTEM_USER_ID;

  // メッセージ内容の更新を監視し、イベントを発火
  useEffect(() => {
    // カスタムイベントを発火
    DispatchEvent(CHAT_MESSAGE_UPDATED_EVENT)
  }, [item.text, item.translations, isLoading]);

  useEffect(() => {
    // 話者分離情報が必要か（isAddedByUser が true の参加者情報が存在するか）確認
    setIsAddedUserExist(participants.findIndex(p => p.isAddedByUser && p.clientId === item.userId) !== -1);
  }, [item.userId, participants]);


  useEffect(() => {
    const speakerId = item.speakerId
    const speakerIdx = speakers.findIndex(s => s.id === speakerId);
    const speaker = speakers[speakerIdx];

    // デフォルトの表示名
    let defaultDisplayName = item?.displayName;
    // STTによって割り振られたラベル
    let speakerLabel = item.speakerId?.split(separateString)[1];
    // 入力された参加者名
    let speakerName = speaker?.username;

    // 表示名を決定する
    let displayName = defaultDisplayName ?? '';
    // 話者分離情報が必要な場合
    if(isAddedUserExist){
      // 話者分離ラベルが割り振られている場合
      if(speakerLabel){
        displayName = speakerLabel;
      }
      // 参加者名が割り振られている場合
      if(speakerName){
        displayName = speakerName;
      }
      // 他の人のメッセージの場合
      if(!isSystem && !isMine){
        displayName = `${defaultDisplayName}${separateString}${displayName}`;
      }
    }
    // 自分自身のメッセージで話者分離情報が不要な場合
    if(isMine && !isAddedUserExist){
      displayName = LocalStorage.name ?? displayName;
    }
    // システムメッセージの場合は翻訳
    if(isSystem){
      displayName = t(displayName);
    }
    setDisplayName(displayName);
  }, [isAddedUserExist, isMine, isSystem, item?.displayName, item.speakerId, speakers, t]);

  return (
      <div style={{ width: 'auto' }} className={`chat-row ${isMine ? '' : 'other'} row justify-content-${isMine ? 'end' : 'start'}`}>
      <div className='col-auto flex-shrink-1'>
        {!isMine && <UserAvatar text={displayName} />}
        <div className='chat-text-container'>
        <SpeakerName roomOwnerId={roomOwnerId} meetingId={meetingId} displayName={displayName} participants={participants} item={item} isMine={isMine} />
          <div className='chat-text'>
            {(isActiveAdvisor && AdvisorState.audioAnalyser && !isLoading) && <Oscilloscope analyser={AdvisorState.audioAnalyser} />}
            {isLoading ? <div className='spinner-border mx-5 my-3' role='status'><span className='visually-hidden'>Loading...</span></div> :
              <>
                <MainText chat={item} />
                {Object.keys(item.translations).map(lang =>
                  (LocalStorage.language.includes((lang as ILanguages)) || (lang as ILanguages) === LocalStorage.translationLanguage) &&
                  (<TranslatedText key={lang} chat={item} language={lang as ILanguages} />)
                )}
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};


export default observer(ChatRow)
